import Typography from "@mui/material/Typography";

export default function SectionFooter({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{ px: 2, pt: 0.25 }}
      display="block"
    >
      {children}
    </Typography>
  );
}
