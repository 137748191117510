import Typography from "@mui/material/Typography";

export default function SectionHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography
      variant="subtitle2"
      color="textSecondary"
      sx={{ px: 2, pb: 0.25 }}
    >
      {children}
    </Typography>
  );
}
