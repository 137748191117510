import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { useState, createRef, useCallback } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, auth } from "utils/firebase";
import Pica from "pica";
import CircularProgress from "@mui/material/CircularProgress";

export type AvatarFieldProps = {
  type: "avatar";
  name: string;
  size?: number;
  maxHeight?: number;
  maxWidth?: number;
  label?: string;
};

export type Props = {
  onChange: (value: string) => void;
  field: AvatarFieldProps;
  values: Record<string, string>;
};

const AVATAR_CACHE_SECONDS = 60 * 60 * 24 * 30; // 1 month

export default function AvatarField({
  onChange,
  field: { size = 100, maxHeight = 512, maxWidth = 512, name },
  values,
}: Props) {
  const inputRef = createRef<HTMLInputElement>();

  const onCickAvatar = useCallback(() => {
    inputRef.current!.click();
  }, [inputRef]);

  const [progress, setProgress] = useState<number>(0);
  const [avatarURL, setAvatarURL] = useState<string>(values[name] || "");
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const originalFile = e.target.files[0];
      const resizedFile = await resizeImage(originalFile, maxWidth, maxHeight);

      handleUpload(resizedFile as File);
    }
  };

  const resizeImage = async (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    const pica = new Pica();
    const img = new Image();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target!.result as string;
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
          canvas.width = img.width * ratio;
          canvas.height = img.height * ratio;

          try {
            const result = await pica.resize(img, canvas);
            console.log(result);
            canvas.toBlob((blob) => {
              if (!blob) return reject("Failed to resize image");

              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });

              resolve(resizedFile);
            }, file.type);
          } catch (err) {
            reject(err);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async (file: File) => {
    if (!file) return;

    setLoading(true);
    const storageRef = ref(
      storage,
      `users/${auth.currentUser!.uid}/avatars/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type,
      cacheControl: `private,max-age=${AVATAR_CACHE_SECONDS},immutable`,
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setAvatarURL(downloadURL);
        setLoading(false);

        // Сохранение URL в Firestore
        console.log("save avatar url form", downloadURL);
        onChange(downloadURL);
      }
    );
  };

  return (
    <ListItem>
      {avatarURL && <input type="hidden" name={name} value={avatarURL} />}
      <input
        type="file"
        accept="image/*"
        hidden
        ref={inputRef}
        onChange={handleFileChange}
      />

      <Avatar
        src={avatarURL}
        sx={{
          width: size,
          height: size,
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={onCickAvatar}
      >
        {loading ? (
          <CircularProgress
            size={size}
            value={progress}
            variant="determinate"
          />
        ) : (
          <PhotoCameraOutlinedIcon />
        )}
      </Avatar>
    </ListItem>
  );
}
