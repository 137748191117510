import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import { useCallback, useState } from "react";
// import ErrorIcon from "@mui/icons-material/Error";
import {
  MDXEditor,
  MDXEditorMethods,
  headingsPlugin,
  listsPlugin,
  // quotePlugin,
  linkPlugin,
  // linkDialogPlugin,
  // thematicBreakPlugin,
} from "@mdxeditor/editor";
import { useRef } from "react";
import "@mdxeditor/editor/style.css";
import "./style.css";

export type MarkdownFieldProps = {
  type: "markdown";
  name: string;
  trim?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  // startAdornment?: React.ReactNode;
  // endAdornment?: React.ReactNode;
  // required?: boolean;
  // helperText?: string;
  placeholder?: string;
  label?: string;
  // onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  // multiline?: boolean;
  autoFocus?: boolean;
  // disabled?: boolean;
};

export type Props = {
  field: MarkdownFieldProps;
  onChange?: (value: string) => void;
  values: Record<string, string>;
};

export default function MarkdownField({
  field: {
    // type,
    name,
    trim,
    lowercase,
    uppercase,
    autoFocus,
    // required,
    // helperText,
    placeholder,
    // ...props
  },
  onChange,
  values,
}: Props) {
  const theme = useTheme();
  // const { t } = useTranslation("components");
  const ref = useRef<MDXEditorMethods>(null);
  // const [visited, setVisited] = useState<boolean>(false);
  const [value, setValue] = useState<string>(values[name] || "");

  const onChangeValue = useCallback(
    (value: string) => {
      if (trim) {
        value = value.trim();
      }

      if (lowercase) {
        value = value.toLowerCase();
      }

      if (uppercase) {
        value = value.toUpperCase();
      }

      setValue(value);

      if (onChange) onChange(value);
    },
    [trim, lowercase, uppercase, onChange]
  );

  // const onBlur = useCallback(() => {
  //   setVisited(true);
  // }, []);

  // const error = visited && required && !value;

  // ref.current?.setMarkdown('new markdown')
  // ref.current?.insertMarkdown('new markdown to insert')
  // ref.current?.getMarkdown()

  return (
    <ListItem>
      <input name={name} type="hidden" value={value} />
      <MDXEditor
        autoFocus={autoFocus}
        className={
          theme.palette.mode === "dark"
            ? "mdx-editor-dark-theme"
            : "mdx-editor-light-theme"
        }
        contentEditableClassName={"mdx-editor-content-editable"}
        ref={ref}
        markdown={value}
        onChange={onChangeValue}
        // onBlur={onBlur}
        // onError={onError}
        placeholder={placeholder}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          // quotePlugin(),
          linkPlugin(),
          // linkDialogPlugin(),
          // thematicBreakPlugin(),
        ]}
      />
    </ListItem>
  );
}
