import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  connectFirestoreEmulator,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getMessaging } from "firebase/messaging";
import { getVertexAI } from "firebase/vertexai";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { isDevelopment } from "utils/env";

export const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: string;
  }
}

if (isDevelopment) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN =
    import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN;
}

export const app = initializeApp(firebaseConfig);

const RECAPTCHA_ENTERPRISE_SITE_KEY = import.meta.env
  .VITE_FIREBASE_RECAPTCHA_ENTERPRISE_SITE_KEY;

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_ENTERPRISE_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const analytics = getAnalytics(app);

if (isDevelopment) {
  setAnalyticsCollectionEnabled(analytics, false);
}

export const auth = getAuth(app);

export const firestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    tabManager: persistentMultipleTabManager(),
  }),
});

export const storage = getStorage(app);

export const functions = getFunctions();

export const messaging = getMessaging(app);

if (isDevelopment) {
  console.log("Emulators enabled");

  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

// Initialize the Vertex AI service
export const vertexAI = getVertexAI(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  sign_up_enabled: false,
};

if (isDevelopment) {
  // The default and recommended production fetch interval for Remote Config is 12 hours
  remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 1; // 5 minutes
}

fetchAndActivate(remoteConfig)
  .then(() => {
    console.log("Remote config fetched and activated");
  })
  .catch((err) => {
    console.error("Error fetching and activating remote config", err);
  });
