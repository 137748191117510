import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router";

export type FormListItemProps = {
  type: "item";
  to?: string;
  avatar?: React.ReactNode;
  icon?: React.ReactNode;
  primary: React.ReactNode;
  primaryTypographyProps?: object;
  secondary?: React.ReactNode;
  secondaryTypographyProps?: object;
  disabled?: boolean;
  action?: React.ReactNode;
  alignItems?: "flex-start" | "center";
  onClick?: () => void;
  disableTypography?: boolean;
};

export type Props = {
  field: FormListItemProps;
};

export default function FormListItem({
  field: {
    to,
    avatar,
    icon,
    primary,
    primaryTypographyProps,
    secondary,
    secondaryTypographyProps,
    disabled,
    action,
    alignItems = "center",
    onClick,
    disableTypography,
  },
}: Props) {
  const inner = (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        disableTypography={disableTypography}
        primary={primary}
        primaryTypographyProps={primaryTypographyProps}
        secondary={secondary}
        secondaryTypographyProps={secondaryTypographyProps}
      />
      {action}
    </>
  );

  if (to || onClick) {
    return (
      <ListItem disablePadding>
        <ListItemButton
          component={to ? Link : "button"}
          to={to}
          disabled={disabled}
          sx={{ alignItems }}
          onClick={onClick}
        >
          {inner}
        </ListItemButton>
      </ListItem>
    );
  }

  return <ListItem sx={{ alignItems }}>{inner}</ListItem>;
}
