import ListItem from "@mui/material/ListItem";

export type ComponentFieldProps = {
  type: "component";
  component: React.ReactNode;
};

export type Props = {
  field: ComponentFieldProps;
};

export default function ComponentField({ field: { component } }: Props) {
  return <ListItem disablePadding>{component}</ListItem>;
}
