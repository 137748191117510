import { ReactNode } from "react";
import type { Viewer } from "features/viewer";
import ViewerContext from "./ViewerContext";

export default function ViewerProvider({
  children,
  viewer,
}: {
  children: ReactNode;
  viewer: Viewer | null;
}) {
  return (
    <ViewerContext.Provider value={viewer}>{children}</ViewerContext.Provider>
  );
}
