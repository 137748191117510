import ListItem from "@mui/material/ListItem";
import ChipParams from "components/ChipParams";

export type ParamsFieldProps = {
  type: "params";
  name: string;
};

export type Props = {
  field: ParamsFieldProps;
  values: Record<string, unknown>;
};

export default function ParamsField({ field, values }: Props) {
  console.log({ field, values });
  return (
    <ListItem>
      <ChipParams />
    </ListItem>
  );
}
