import ListItem from "@mui/material/ListItem";
// import Avatar from "@mui/material/Avatar";
// import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
// import { createRef, useCallback } from "react";
// import { useScreenContext } from "components/Navigation";

export type AssetFieldProps = {
  type: "asset";
  name: string;
};

export type Props = {
  field: AssetFieldProps;
  values: Record<string, string>;
};

export default function AssetField(props: Props) {
  const {
    field: { name },
    values,
  } = props;

  return <ListItem>{values[name]}</ListItem>;
}
