import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import { useCallback, useState } from "react";

export type SwitchFieldProps = {
  type: "switch";
  name: string;
  icon?: React.ReactNode;
  iconColor?: string;
  color?: "primary" | "secondary";
  primary?: string;
  secondary?: string;
  onChange?: (value: boolean) => void;
};

export type Props = {
  onChange?: (value: boolean) => void;
  field: SwitchFieldProps;
  values: Record<string, boolean>;
};

export default function SwitchField({
  onChange: onChangeField,
  field: { name, icon, iconColor, color, primary, secondary, onChange },
  values,
}: Props) {
  const [value, setValue] = useState(!!values[name]);

  const onChangeChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
      if (onChange) onChange(event.target.checked);
      if (onChangeField) onChangeField(event.target.checked);
    },
    [onChange, onChangeField]
  );

  return (
    <ListItem>
      {icon && (
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: iconColor,
              color: "white",
              borderRadius: 2,
            }}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
      )}

      {(primary || secondary) && (
        <ListItemText primary={primary} secondary={secondary} />
      )}

      <Switch
        color={color}
        checked={value}
        value={value}
        name={name}
        onChange={onChangeChecked}
      />
    </ListItem>
  );
}
