import { Fragment, ReactNode } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router";
import Card from "@mui/material/Card";

export interface MenuItem {
  primary: string;
  secondary?: string;
  avatar?: string;
  icon?: ReactNode;
  iconColor?: string;
  rightIcon?: ReactNode;
  link?: {
    to?: string;
    href?: never;
  };
  disabled?: boolean;
  onClick?: () => void;
}

export default function Menu({ menu }: { menu: MenuItem[][] }) {
  return (
    <Stack spacing={1}>
      {menu.map((section, index) => (
        <Card elevation={0} square key={index}>
          <List disablePadding key={index}>
            {section.map((item, index) => {
              if (item.disabled) return null;
              const inner = (
                <>
                  {item.icon && (
                    <ListItemIcon>
                      <Avatar
                        variant="rounded"
                        sx={{
                          bgcolor: item.iconColor,
                          backgroundImage:
                            "linear-gradient(45deg, rgba(0,0,0,0.3), transparent)",
                          color: "white",
                          borderRadius: 2,
                        }}
                      >
                        {item.icon}
                      </Avatar>
                    </ListItemIcon>
                  )}
                  {item.avatar && (
                    <ListItemAvatar>
                      <Avatar src={item.avatar} />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                    slotProps={{
                      primary: {
                        noWrap: true,
                      },
                      secondary: {
                        noWrap: true,
                      },
                    }}
                  />
                  {item.rightIcon}
                </>
              );

              return (
                <Fragment key={index}>
                  {/* {index > 0 && (
                    <Divider sx={{ borderColor: "background.default" }} />
                  )} */}

                  <ListItem key={index} disablePadding disableGutters>
                    {item.link?.to ? (
                      <ListItemButton component={Link} to={item.link.to}>
                        {inner}
                      </ListItemButton>
                    ) : item.link?.href ? (
                      <ListItemButton
                        component="a"
                        target="_blank"
                        {...item.link}
                      >
                        {inner}
                        <OpenInNewIcon color="disabled" />
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={item.onClick}>
                        {inner}
                      </ListItemButton>
                    )}
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Card>
      ))}
    </Stack>
  );
}
