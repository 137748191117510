import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ReactNode } from "react";

interface CustomError extends Error {
  code?: string;
}

export default function ErrorScreen({
  icon,
  title,
  text,
  button,
  error,
}: {
  icon?: ReactNode;
  title?: string;
  text?: string;
  button?: ReactNode;
  error?: CustomError;
}) {
  if (error) {
    console.dir(error);
    title = error.code || "An error occurred";
    text = "Please try again later";
  }

  return (
    <Paper
      elevation={0}
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: 10,
        px: 2,
      }}
    >
      <Stack spacing={2} alignItems="center">
        {icon}

        <div>
          <Typography
            color="textPrimary"
            variant="h5"
            align="center"
            gutterBottom
          >
            {title}
          </Typography>

          <Typography color="textSecondary" variant="body2" align="center">
            {text}
          </Typography>
        </div>

        {button}
      </Stack>
    </Paper>
  );
}
