import { Timestamp } from "firebase/firestore";

export enum PushNotificationStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Default = "default",
  Denied = "denied",
  Loading = "loading",
  Error = "error",
}

export type Permission = "default" | "granted" | "denied";

export enum NotificationType {
  WORKOUT_COMPLETED = "WORKOUT_COMPLETED",
  EXERCISE_COMPLETED = "EXERCISE_COMPLETED",
  EXERCISE_RESULT_CREATED = "EXERCISE_RESULT_CREATED",
  CLIENT_COMMENT_CREATED = "CLIENT_COMMENT_CREATED",
  WORKOUT_COMMENT_CREATED = "WORKOUT_COMMENT_CREATED",
  EXERCISE_COMMENT_CREATED = "EXERCISE_COMMENT_CREATED",
  CLIENT_INVITATION_ACCEPTED = "CLIENT_INVITATION_ACCEPTED",
}

export interface Notification extends NotificationData {
  id: string;
}

export interface NotificationData {
  type: NotificationType;
  data: NotificationData;
  isRead: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  client?: {
    id: string;
    displayName?: string;
    photoURL?: string;
  };
  coach?: {
    id: string;
    displayName?: string;
    photoURL?: string;
  };
  workout?: {
    id: string;
    title: string;
  };
  exercise?: {
    id: string;
    title: string;
  };
  result?: {
    id: string;
    text: string;
  };
  comment?: {
    id: string;
    text: string;
  };
  creator?: {
    id: string;
    role: "client" | "coach";
    displayName?: string;
    photoURL?: string;
  };
}
