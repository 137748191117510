import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";

export default function ChipParams() {
  const params = [
    { label: "10 reps" },
    { label: "3 sets" },
    { label: "30 sec rest" },
    { label: "5 min warmup" },
  ];
  return (
    <Grid container spacing={1}>
      {params.map((param, index) => (
        <Grid key={index}>
          <Chip
            label={param.label}
            size="medium"
            variant="filled"
            onDelete={() => {}}
            onClick={() => {}}
          />
        </Grid>
      ))}
      <Grid>
        <Chip
          icon={<AddIcon />}
          label="add param"
          variant="filled"
          size="medium"
          onClick={() => {}}
        />
      </Grid>
    </Grid>
  );
}
