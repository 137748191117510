import { MessagePayload, onMessage } from "firebase/messaging";
import { messaging } from "utils/firebase";
import api from "utils/api";

export async function enableNotifications(values: { token: string }) {
  await api("createPushNotificationToken", values);
}

export async function disableNotifications(values: { token: string }) {
  await api("deletePushNotificationToken", values);
}

export function markNotificationAsRead(values: { notificationId: string }) {
  return api("markNotificationAsRead", values);
}

export function markNotificationAsUnread(values: { notificationId: string }) {
  return api("markNotificationAsUnread", values);
}

export function markAllNotificationsAsRead() {
  return api("markAllNotificationsAsRead");
}

// тут мы должны проверить, что пользователь сейчас не находится на странице со списком уведомлений
// и не находится на странице с открытым чатом в который это уведомление приехало
// если пользователь находится на странице с уведомлениями, то мы ничего можем не делать, так как пользователь и так увидит это уведомление в списке (разве что можно проиграть звук нового уведомления)
// если пользователь находится на странице с открытым чатом в который было отправлено новое сообщение, то тоже можно ничего не делать (разве что можно проиграть звук нового уведомления)
// если пользователь находится на любой другой странице, то мы должны показать браузерное уведомление и проиграть звук нового уведомления
onMessage(messaging, (payload: MessagePayload) => {
  if (!payload.data) return;
  if (!payload.notification) return;
  if (!payload.notification.title) return;

  const link = payload.fcmOptions?.link || payload.data?.link;

  if (new URL(link).pathname === window.location.pathname) {
    console.log("Уведомление отправлено в текущую вкладку, игнорируем его");
    return;
  }

  const notification = new Notification(payload.notification.title, {
    body: payload.notification.body,
    icon: payload.notification.icon,
    data: { link: payload.fcmOptions?.link }, // Сохранение ссылки
  });

  notification.onclick = (event) => {
    event.preventDefault(); // Предотвращаем закрытие
    // console.log("notification", notification, link, event);
    window.focus();
    window.open(link, "_self");
    notification.close();

    // window.location.href = notification.data.link;
    // window.open(notification.data.link);
  };

  console.log("Получено уведомление", payload);
});
