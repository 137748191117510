import MuiContainer from "@mui/material/Container";

export default function Container({
  children,
  margin,
  disableGutters,
  height,
  flex,
  maxWidth = false,
}: {
  children: React.ReactNode;
  margin?: string;
  disableGutters?: boolean;
  height?: string;
  flex?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}) {
  return (
    <MuiContainer
      maxWidth={maxWidth}
      disableGutters={disableGutters}
      sx={{
        p: disableGutters ? 0 : { xs: 1, md: 2 },
        margin,
        height,
        flex,
      }}
    >
      {children}
    </MuiContainer>
  );
}
