import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./theme";

declare module "@mui/material/styles" {
  export interface ThemeOptions {
    colorSchemes: {
      light: {
        palette: {
          blockquote: {
            main: string;
          };
        };
      };
      dark: {
        palette: {
          blockquote: {
            main: string;
          };
        };
      };
    };
  }
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <MuiThemeProvider theme={theme} noSsr>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
}
