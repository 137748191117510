import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "utils/ui";

export type ButtonFieldProps = {
  type: "button" | "submit";
  label: string;
  link?: { to: string; replace?: boolean };
  position?: "fixed";
  color?: "primary" | "secondary";
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  onClick?: () => void;
};

export type Props = {
  saved?: boolean;
  loading: boolean;
  field: ButtonFieldProps;
};
export default function ButtonField({
  saved,
  loading,
  field: { label, link, position, type, ...props },
}: Props) {
  let disabled = false;
  if (type === "submit") {
    disabled = loading;
    if (saved) {
      disabled = true;
    }
  }

  const button = link ? (
    <Button component={Link} {...link} fullWidth {...props}>
      {label}
    </Button>
  ) : (
    <Button fullWidth {...props} type={type} disabled={disabled}>
      {loading ? "loading..." : label}
    </Button>
  );

  if (position === "fixed") {
    return (
      <>
        <Box sx={{ height: 200 }} />
        <Box
          sx={{
            padding: 0,
            margin: 0,
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            paddingBottom: "calc(env(safe-area-inset-bottom, 0))",
            bgcolor: "background.paper",
            zIndex: 1100,
          }}
        >
          <Divider sx={{ borderColor: "background.default" }} />
          <Box
            sx={{
              p: 2,
              marginLeft: { xs: 0, md: `${LEFT_SIDEBAR_WIDTH}px` },
              marginRight: { xs: 0, lg: `${RIGHT_SIDEBAR_WIDTH}px` },
            }}
          >
            {button}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <ListItem disableGutters disablePadding>
      {button}
    </ListItem>
  );
}
