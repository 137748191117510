import ButtonLink from "components/ButtonLink";
import ErrorScreen from "components/ErrorScreen";
import { useRouteError } from "react-router";
import BugReportIcon from "@mui/icons-material/BugReport";

interface Error {
  status: number;
  statusText: string;
}

export default function ErrorBoundary() {
  const error = useRouteError() as Error;

  console.error(error);

  if (error.status) {
    return (
      <ErrorScreen
        title={error.status.toString()}
        text={error.statusText}
        icon={<BugReportIcon fontSize="large" />}
        button={
          <ButtonLink to="/" variant="contained" color="primary" size="large">
            Go back
          </ButtonLink>
        }
      />
    );
  }

  return (
    <ErrorScreen
      title="Error"
      text="An unexpected error occurred."
      icon={<BugReportIcon fontSize="large" />}
    />
  );
}
