import { httpsCallable } from "firebase/functions";
import { functions } from "utils/firebase";

const apiCall = httpsCallable(functions, "api");

// методы для админа
type AdminApiOperation =
  | "runMigration"
  | "getMigrationsList"

  // Chat operations (это на время разработки а потом надо будет перенести куда надо)
  | "markChatMessagesAsRead"
  | "createSystemChatMessage";

// методы для клиента
type ClientApiOperation =
  | "updateWorkout"
  | "updateWorkoutExercise"
  | "createExerciseResult";

// методы для тренера
type CoachApiOperation =
  | "addExerciseToClientWorkout"
  | "addExerciseToProgramWorkout"
  | "createClient"
  | "createClientWorkout"
  | "createClientWorkoutExercise"
  | "createExercise"
  | "createProgram"
  | "createProgramWorkout"
  | "createProgramWorkoutExercise"
  | "createTeamMember"
  | "createVideoUpload"
  | "archiveClient"
  | "deleteClientWorkout"
  | "deleteClientWorkoutExercise"
  | "deleteExercise"
  | "deleteProgram"
  | "deleteProgramWorkout"
  | "deleteProgramWorkoutExercise"
  | "resendInvitation"
  | "restoreClient"
  | "updateClientWorkout"
  | "updateClientWorkoutExercise"
  | "updateExercise"
  | "updateProgram"
  | "updateProgramWorkout"
  | "updateProgramWorkoutExercise"
  | "updateTeam";

// методы для не авторизованного юзера
type GuestApiOperation = "acceptInvitation" | "requestAnInvitation" | "signUp";

// методы для авторизованного юзера
type UserApiOperation =
  | "createPushNotificationToken"
  | "deletePushNotificationToken"
  | "markAllNotificationsAsRead"
  | "markNotificationAsRead"
  | "markNotificationAsUnread"
  | "updateUser";

type ApiOperation =
  | AdminApiOperation
  | ClientApiOperation
  | CoachApiOperation
  | GuestApiOperation
  | UserApiOperation;

export default function mutate(operation: ApiOperation, payload?: object) {
  return apiCall({ operation, payload });
}
