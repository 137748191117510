import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import List from "@mui/material/List";

import MarkdownField from "./MarkdownField/index";
import TagsField from "./TagsField";
import AssetField from "./AssetField";
import AvatarField from "./AvatarField";
import TextField from "./TextField";
import SwitchField from "./SwitchField";
import DateField from "./DateField";
import LinkField from "./LinkField";
import RadioField from "./RadioField";
import ButtonField from "./ButtonField";
import TypographyField from "./TypographyField";
import ComponentField from "./ComponentField";
import SelectField from "./SelectField";
import ParamsField from "./ParamsField";
import FormListItem from "./FormListItem";

import type { Section, FieldProps } from "./types";

const fieldTypes = {
  markdown: MarkdownField,
  params: ParamsField,
  tags: TagsField,
  select: SelectField,
  asset: AssetField,
  avatar: AvatarField,
  switch: SwitchField,
  text: TextField,
  email: TextField,
  url: TextField,
  password: TextField,
  number: TextField,
  date: DateField,
  link: LinkField,
  radio: RadioField,
  button: ButtonField,
  submit: ButtonField,
  typography: TypographyField,
  // video: VideoField,
  component: ComponentField,
  item: FormListItem,
} as const;

export default function SectionBody({
  section,
  values = {},
  loading = false,
  saved = false,
  onChange = () => {},
}: {
  section: Section;
  values: Record<string, unknown>;
  loading: boolean;
  saved: boolean;
  onChange?: (values: Record<string, unknown>) => void;
}) {
  return (
    <Card
      elevation={0}
      square={section.square}
      sx={{ bgcolor: section.backgroundColor }}
    >
      <List disablePadding>
        {section.fields.map((field: FieldProps, index: number) => {
          const onChangeField = (value: unknown) => {
            if (!("name" in field)) return;

            onChange({
              ...values,
              [field.name]: value,
            });
          };

          const Component = fieldTypes[field.type] as React.ElementType;

          return (
            <Fragment key={index}>
              {index > 0 && section.dividerVariant && (
                <Divider
                  variant={section.dividerVariant}
                  component="li"
                  sx={{ borderColor: "background.default" }}
                />
              )}

              <Component
                saved={saved}
                loading={loading}
                field={field}
                values={values}
                onChange={onChangeField}
              />
            </Fragment>
          );
        })}
      </List>
    </Card>
  );
}
