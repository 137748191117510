import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCallback, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

export type SelectFieldProps = {
  type: "select";
  name: string;
  startAdornment?: React.ReactNode;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  options: { label: string; value: string }[];
};

export type Props = {
  field: SelectFieldProps;
  onChange?: (value: string | null) => void;
  values: Record<string, string | null>;
};

export default function SelectField({
  field: {
    name,
    options = [],
    startAdornment,
    required,
    helperText,
    placeholder,
    disabled,
  },
  onChange,
  values,
}: Props) {
  const { t } = useTranslation("components");
  const [visited, setVisited] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(values[name] || null);

  const onChangeValue = useCallback(
    (_: unknown, newValue: { label: string; value: string } | null) => {
      const value = newValue ? newValue.value : null;
      setValue(value);
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const onBlur = useCallback(() => {
    setVisited(true);
  }, []);

  const error = visited && required && !value;

  return (
    <ListItem>
      <input name={name} value={value || ""} type="hidden" />
      <Autocomplete
        // disablePortal
        onBlur={onBlur}
        fullWidth
        disabled={disabled}
        options={options}
        onChange={onChangeValue}
        value={options.find((option) => option.value === value) || null}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error}
              helperText={error ? t("form.requiredField") : helperText}
              required={required}
              placeholder={placeholder}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                startAdornment: startAdornment && (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ),
                endAdornment: error ? (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translate(0, -50%)",
                    }}
                  >
                    <ErrorIcon color="error" />
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                ),
                disableUnderline: true,
              }}
            />
          );
        }}
      />
    </ListItem>
  );
}
