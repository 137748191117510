import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import MuiTextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export type TextFieldProps = {
  type: "text" | "email" | "password" | "number" | "url";
  name: string;
  trim?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
};

export type Props = {
  field: TextFieldProps;
  onChange?: (value: string) => void;
  values: Record<string, string>;
};

export default function TextField({
  field: {
    type,
    name,
    trim,
    lowercase,
    uppercase,
    startAdornment,
    endAdornment,
    required,
    helperText,
    placeholder,
    ...props
  },
  onChange,
  values,
}: Props) {
  const { t } = useTranslation("components");
  const [visited, setVisited] = useState<boolean>(false);
  const [value, setValue] = useState<string>(values[name] || "");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const onClickTogglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((prev) => !prev);
  }, []);

  const onChangeValue = useCallback(
    (event: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let value = event.currentTarget.value;

      if (trim) {
        value = value.trim();
      }

      if (lowercase) {
        value = value.toLowerCase();
      }

      if (uppercase) {
        value = value.toUpperCase();
      }

      setValue(value);

      if (onChange) onChange(value);
    },
    [trim, lowercase, uppercase, onChange]
  );

  const onBlur = useCallback(() => {
    setVisited(true);
  }, []);

  const error = visited && required && !value;

  const showPasswordVisibility = type === "password" && value.length > 0;

  return (
    <ListItem>
      <MuiTextField
        type={type === "password" && isPasswordVisible ? "text" : type}
        name={name}
        required={required}
        placeholder={placeholder}
        fullWidth
        variant="standard"
        error={error}
        helperText={error ? t("form.requiredField") : helperText}
        onBlur={onBlur}
        slotProps={{
          input: {
            disableUnderline: true,
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: error ? (
              <InputAdornment position="end">
                <ErrorIcon color="error" />
              </InputAdornment>
            ) : endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : showPasswordVisibility ? (
              <InputAdornment position="end">
                <TogglePasswordVisibility
                  onClick={onClickTogglePasswordVisibility}
                  visible={isPasswordVisible}
                />
              </InputAdornment>
            ) : null,
          },
        }}
        {...props}
        onChange={onChangeValue}
        value={value}
      />
    </ListItem>
  );
}

function TogglePasswordVisibility({
  onClick,
  visible,
}: {
  onClick?: () => void;
  visible: boolean;
}) {
  const { t } = useTranslation("components");
  const Icon = visible ? VisibilityOffIcon : VisibilityIcon;
  const title = visible ? t("form.hidePassword") : t("form.showPassword");

  return (
    <Tooltip title={title} arrow>
      <IconButton onClick={onClick}>
        <Icon color="action" />
      </IconButton>
    </Tooltip>
  );
}
