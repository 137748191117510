import { Link } from "react-router";
import Button from "@mui/material/Button";

export default function ButtonLink({
  children,
  to,
  ...props
}: {
  children: React.ReactNode;
  to: string;
  [key: string]: unknown;
}) {
  return (
    <Button component={Link} to={to} {...props}>
      {children}
    </Button>
  );
}
