import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#418af4",
        },
        background: {
          default: "#f1f1f1",
        },
        blockquote: {
          main: "rgba(0,136,255,0.025)",
        },
      },
      // components: {
      //   MuiDivider: {
      //     styleOverrides: {
      //       root: {
      //         borderColor: "rgba(0,0,0,0.06)",
      //       },
      //     },
      //   },
      // },
    },
    dark: {
      palette: {
        primary: {
          main: "#418af4",
        },
        background: {
          default: "#000",
        },
        blockquote: {
          main: "rgba(0,136,255,0.025)",
        },
      },
      // components: {
      //   MuiDivider: {
      //     styleOverrides: {
      //       root: {
      //         borderColor: "rgba(255,255,255,0.06)",
      //       },
      //     },
      //   },
      // },
    },
  },

  typography: {
    fontFamily: [
      "Ubuntu",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        html: {
          overscrollBehavior: "none",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          // width: "100%",
          // overscrollBehavior: "none",
        },
        body: {
          overscrollBehavior: "none", // отключает эффект pull-to-refresh
          // "-webkit-overflow-scrolling": "touch",
          // overscroll-behavior-y: contain;
          // overscrollBehaviorY: "contain", // отключает эффект pull-to-refresh
          // overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          // width: "100%",
          flex: 1,
          // margin: "0 auto",

          "& .firebase-emulator-warning": {
            display: "none",
          },
        },
        "#root": {
          minHeight: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          // paddingLeft: "env(safe-area-inset-left, 0)",
          // paddingRight: "env(safe-area-inset-right, 0)",
          // paddingBottom: "env(safe-area-inset-bottom, 0)",
          // paddingTop: "env(safe-area-inset-top, 0)",
        },
        a: {
          WebkitTouchCallout: "none",
        },
      }),
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          // borderRadius: 150,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});
