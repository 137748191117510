import { useSyncExternalStore } from "react";
import OnlineStatusContext from "./OnlineStatusContext";

export default function OnlineStatusProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const isOnline = useSyncExternalStore(subscribe, getSnapshot);

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
    </OnlineStatusContext.Provider>
  );
}

function getSnapshot() {
  return navigator.onLine;
}

function subscribe(callback: (event: Event) => void) {
  window.addEventListener("online", callback);
  window.addEventListener("offline", callback);
  return () => {
    window.removeEventListener("online", callback);
    window.removeEventListener("offline", callback);
  };
}
