import { useEffect, useReducer } from "react";
import { onAuthStateChanged, type User } from "firebase/auth";
import { onSnapshot, doc } from "firebase/firestore";
import { auth, firestore } from "utils/firebase";
import { useTranslation } from "react-i18next";
import { type Viewer } from "features/viewer";
import dayjs from "utils/dayjs";
import { setUserId } from "utils/analytics";

enum ActionType {
  SET_USER = "SET_USER",
  SET_USER_PROFILE = "SET_USER_PROFILE",
}

type Action =
  | { type: ActionType.SET_USER; user: User | null }
  | { type: ActionType.SET_USER_PROFILE; userProfile: Viewer | null };

const initialState = {
  user: null,
  userLoading: true,

  userProfile: null,
  userProfileLoading: true,
} as {
  user: User | null;
  userLoading: boolean;
  userProfile: Viewer | null;
  userProfileLoading: boolean;
};

function reducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.user,
        userLoading: false,
        userProfile: null,
      };

    case ActionType.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile,
        userProfileLoading: false,
      };

    default:
      return state;
  }
}

export function useUserProfile() {
  const { i18n } = useTranslation("core");
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user, userLoading, userProfile, userProfileLoading } = state;

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      dispatch({ type: ActionType.SET_USER, user });
    });
  }, []);

  useEffect(() => {
    if (!state.user) return;

    return onSnapshot(doc(firestore, "users", state.user.uid), (doc) => {
      if (!doc.exists()) return;

      const userData = doc.data();

      const userProfile = {
        uid: doc.id,
        email: state.user!.email,
        ...userData,
      } as Viewer;

      if (userProfile.language) {
        i18n.changeLanguage(userProfile.language);
      }

      if (userProfile.timeZone) {
        dayjs.tz.setDefault(userProfile.timeZone);
      }

      setUserId(userProfile.uid);

      dispatch({
        type: ActionType.SET_USER_PROFILE,
        userProfile,
      });
    });
  }, [state.user, i18n]);

  const loading = user ? userProfileLoading : userLoading;

  return { user, loading, userProfile };
}
