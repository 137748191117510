import OnlineStatusContext from "./OnlineStatusContext";
import { useContext } from "react";

/**
 * This hook returns the current online status of the navigator.
 * @returns {boolean} isOnline
 */
export default function useOnlineStatus() {
  const isOnline = useContext(OnlineStatusContext);

  return isOnline;
}
