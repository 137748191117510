import { Fragment, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export type RadioFieldProps = {
  type: "radio";
  name: string;
  label?: string;
  options: { label: string; value: string }[];
  onChange?: (value: string) => void;
};

export type Props = {
  field: RadioFieldProps;
  values: Record<string, string>;
  onChange?: (value: string) => void;
};

export default function RadioField({
  onChange: onChangeField,
  field: { name, options, onChange },
  values,
}: Props) {
  const [value, setValue] = useState(values[name]);
  return (
    <>
      {options.map((option, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <Divider
              variant="middle"
              component="li"
              sx={{ borderColor: "background.default" }}
            />
          )}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setValue(option.value);
                if (onChange) onChange(option.value);
                if (onChangeField) onChangeField(option.value);
              }}
            >
              <ListItemText primary={option.label} />

              {value === option.value && <CheckCircleIcon color="primary" />}
            </ListItemButton>
          </ListItem>
        </Fragment>
      ))}
      <input type="hidden" name={name} value={value} />
    </>
  );
}
