import ListItem from "@mui/material/ListItem";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "utils/dayjs";

export type DateFieldProps = {
  type: "date";
  name: string;
  label?: string;
  required?: boolean;
};

export type Props = {
  field: DateFieldProps;
  values: Record<string, string>;
};

export default function DateField({
  field: { name, label, required },
  values,
}: Props) {
  return (
    <ListItem>
      <MobileDatePicker
        // fullWidth
        name={name}
        label={label}
        format="DD MMMM YYYY"
        defaultValue={values[name!] ? dayjs(values[name]) : null}
        slotProps={{
          textField: {
            required,
            fullWidth: true,
            variant: "standard",
            InputProps: {
              disableUnderline: true,
            },
          },
        }}
      />
    </ListItem>
  );
}
