import { analytics } from "utils/firebase";
import {
  logEvent as logFirebaseAnalyticsEvent,
  setUserProperties as setFirebaseAnalyticsUserProperties,
  setUserId as setFirebaseAnalyticsUserId,
} from "firebase/analytics";

// Список рекомендованных событий и их параметров:
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag

export function logEvent(
  eventName: string,
  eventParams?: Record<string, unknown>
): void {
  logFirebaseAnalyticsEvent(analytics, eventName, eventParams);
}

export function setUserProperties(
  userProperties: Record<string, unknown>
): void {
  setFirebaseAnalyticsUserProperties(analytics, userProperties);
}

export function setUserId(userId: string): void {
  setFirebaseAnalyticsUserId(analytics, userId);
}
