import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useMemo, useState, useCallback, Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import MuiTextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";

export type TagsFieldProps = {
  type: "tags";
  name: string;
  placeholder: string;
  addPlaceholder: string;
  deleteTooltip: string;
};

export type Props = {
  field: TagsFieldProps;
  onChange: (value: string[]) => void;
  values: Record<string, string[]>;
};

type Option = {
  label: string;
  value: string | number;
};

type NewItem = {
  label: string;
  value: string | number;
  placeholder: string;
};

type Item = Option | NewItem;

export default function TagsField({
  field: { name, placeholder, addPlaceholder, deleteTooltip },
  onChange,
  values, // initial values (does not change after the component is mounted)
}: Props) {
  const [value, setValue] = useState<string[]>(values[name] || []);

  const items: Item[] = useMemo(
    () => [
      ...value.map((value) => ({ label: value, value })),
      {
        placeholder: addPlaceholder,
        label: "",
        value: "",
      },
    ],
    [value, addPlaceholder]
  );

  const onChangeValue = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((prev) => {
        const next = [...prev];
        next[index] = event.target.value;
        if (onChange) onChange(next);
        return next;
      });
    },
    [onChange]
  );

  const onClickDelete = useCallback(
    (index: number) => () => {
      setValue((prev) => {
        const next = [...prev];
        next.splice(index, 1);
        if (onChange) onChange(next);
        return next;
      });
    },
    [onChange]
  );

  const lastIndex = items.length - 1;

  return (
    <>
      {items.map((option, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <Divider
              sx={{ borderColor: "background.default" }}
              component="li"
            />
          )}
          <ListItem
            secondaryAction={
              items.length > 1 &&
              index < items.length - 1 && (
                <Tooltip title={deleteTooltip}>
                  <IconButton edge="end" onClick={onClickDelete(index)}>
                    <ClearIcon color="action" />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ItemField
              name={lastIndex !== index ? name : undefined}
              placeholder={
                "placeholder" in option ? option.placeholder : placeholder
              }
              value={option.value}
              onChange={onChangeValue(index)}
            />
          </ListItem>
        </Fragment>
      ))}
    </>
  );
}

function ItemField({
  name,
  placeholder,
  value,
  onChange,
}: {
  name?: string;
  placeholder?: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { t } = useTranslation("components");
  const [visited, setVisited] = useState<boolean>(false);

  const onBlur = useCallback(() => {
    if (!name) return;
    setVisited(true);
  }, [name]);

  const error = visited && !!name && !value;

  return (
    <MuiTextField
      name={name}
      placeholder={placeholder}
      fullWidth
      variant="standard"
      error={error}
      helperText={error ? t("form.requiredField") : undefined}
      onBlur={onBlur}
      slotProps={{
        input: {
          disableUnderline: true,
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        },
      }}
      onChange={onChange}
      value={value}
    />
  );
}
