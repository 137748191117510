import { useOnlineStatus } from "components/OnlineStatusProvider";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import WifiOffIcon from "@mui/icons-material/WifiOff";

export default function OfflineAlert() {
  const isOnline = useOnlineStatus();

  if (isOnline) return null;

  return (
    <Alert
      severity="error"
      variant="filled"
      icon={<WifiOffIcon />}
      sx={{ borderRadius: 0 }}
    >
      <AlertTitle>You are offline</AlertTitle>
      Some features may not be available.
    </Alert>
  );
}
