import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

export type TypographyFieldProps = {
  type: "typography";
  text: React.ReactNode;
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2";
  align?: "inherit" | "left" | "center" | "right" | "justify";
};

export type Props = {
  field: TypographyFieldProps;
};

export default function TypographyField({
  field: { text, color, variant, align },
}: Props) {
  return (
    <ListItem>
      <Typography
        variant={variant}
        color={color}
        align={align}
        sx={{ width: "100%" }}
      >
        {text}
      </Typography>
    </ListItem>
  );
}
