interface AppleNavigator extends Navigator {
  standalone: boolean;
}

/**
 * Check if the app is running in development mode.
 */
export const isDevelopment: boolean = import.meta.env.DEV;

/**
 * Check if the app is running in production mode.
 */
export const isProduction: boolean = import.meta.env.PROD;

/**
 * Check if the app is running in standalone mode (PWA).
 */
export const isStandalone: boolean =
  (navigator as AppleNavigator).standalone ||
  window.matchMedia("(display-mode: standalone)").matches;

// https://web.dev/learn/pwa/detection

export function isIOS(): boolean {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function isAndroid(): boolean {
  return /Android/.test(navigator.userAgent);
}
