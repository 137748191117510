import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import SectionHeader from "./SectionHeader";
import SectionBody from "./SectionBody";
import SectionFooter from "./SectionFooter";
import { useCallback, useState, SyntheticEvent } from "react";
import { enqueueSnackbar } from "notistack";
import type { Schema, Section } from "./types";

export default function FormList({
  schema,
  values = {},
  onChange,
  onSubmit,
  spacing = 2,
  defaultSaved = true,
}: {
  schema: Schema;
  values?: any;
  onChange?: (event: any) => void;
  onSubmit?: (values: any) => Promise<void>; // Updated type to Record<string, unknown>
  spacing?: number;
  defaultSaved?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(defaultSaved);

  const onSubmitForm = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!onSubmit) return;
      setLoading(true);

      const formData = new FormData(event.currentTarget);

      const values: Record<string, unknown> = {};

      for (const pair of formData.entries()) {
        const [key, value] = pair;
        if (values[key]) {
          if (Array.isArray(values[key])) {
            values[key].push(value);
          } else {
            values[key] = [values[key], value];
          }
        } else {
          values[key] = value;
        }
      }

      onSubmit(values)
        ?.then(() => {
          setLoading(false);
          setSaved(true);
        })
        ?.catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
          console.error(error);
          setLoading(false);
        });
    },
    [onSubmit]
  );

  const onChangeField = (event: Record<string, unknown>) => {
    setSaved(false);
    if (onChange) onChange(event);
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Stack spacing={spacing}>
        {schema.map((section: Section, index: number) => (
          <Box key={index}>
            {section.header && (
              <SectionHeader>{section.header.toUpperCase()}</SectionHeader>
            )}

            <SectionBody
              section={section}
              values={values}
              onChange={onChangeField}
              loading={loading}
              saved={saved}
            />

            {section.footer && <SectionFooter>{section.footer}</SectionFooter>}
          </Box>
        ))}
      </Stack>
    </form>
  );
}
