import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu, { MenuItem } from "components/Menu";
import useIsMobile from "utils/hooks/useIsMobile";

export default function LeftSidebar({
  open,
  toggleSidebar,
  drawerWidth,
  menuItems,
}: {
  open: boolean;
  toggleSidebar: (open: boolean) => () => void;
  drawerWidth: number;
  menuItems: MenuItem[][];
}) {
  const isMobile = useIsMobile("md");

  const drawerList = (
    <Box role="presentation" onClick={toggleSidebar(false)}>
      <Menu menu={menuItems} />
    </Box>
  );

  return (
    <Drawer
      open={open || !isMobile}
      onClose={toggleSidebar(false)}
      elevation={0}
      variant={isMobile ? "temporary" : "permanent"}
      anchor="left"
      sx={{
        "& .MuiDrawer-paper": {
          width: isMobile ? "100vw" : drawerWidth,
          msOverflowStyle: "none", // IE 10+
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Safari and Chrome
          },
          borderRight: "1px solid",
          borderColor: "background.default",
        },
      }}
    >
      {drawerList}
      <Box sx={{ mt: "auto" }}>
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          display="block"
        >
          {`version: ${__BUILD_DATE__} ${__COMMIT_HASH__}`}
        </Typography>
      </Box>
    </Drawer>
  );
}
