import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router";

export type LinkFieldProps = {
  type: "link";
  primary?: string;
  secondary?: string;
  to?: string;
  href?: string;
};

export type Props = {
  field: LinkFieldProps;
};

export default function LinkField({
  field: { primary, secondary, to, href },
}: Props) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={href ? "a" : Link}
        to={to}
        href={href}
        target={href?.startsWith("http") ? "_blank" : undefined}
      >
        {primary && <ListItemText primary={primary} />}
        {secondary && (
          <Typography color="textSecondary" sx={{ mr: 1 }}>
            {secondary}
          </Typography>
        )}
        <KeyboardArrowRightIcon color="disabled" />
      </ListItemButton>
    </ListItem>
  );
}
